<div class="row mb-2 mx-2 d-flex align-items-center">
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('+')" class="input-group-text" type="button">+</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('#')" class="input-group-text" type="button">#</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('%')" class="input-group-text" type="button">%</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('!')" class="input-group-text" type="button">!</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('*')" class="input-group-text" type="button">*</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('&')" class="input-group-text" type="button">&</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('+')" class="input-group-text" type="button">+</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('#')" class="input-group-text" type="button">#</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('%')" class="input-group-text" type="button">%</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('!')" class="input-group-text" type="button">!</button></div>
    <div class="ml-2"><button (click)="onSpecialCharacterEnter('%')" class="input-group-text" type="button">%</button></div>
</div>